// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'BT',
                    label: '舆情名称',
                    width: 650,
                }, {
                    prop: 'ZFM_DESC',
                    label: '情感分析',
                    width: 80,
                    showoverflowtooltip: true
                }, {
                    prop: 'FBSJ',
                    label: '发布时间',
                    width: 120,
                    substr: true,
                    sortable: "custom",
                }, {
                    prop: 'LY',
                    label: '来源',
                    width: 150,
                    showoverflowtooltip: true
                }, {
                    prop: 'SFYCZ_DESC',
                    label: '是否已有处置',
                    width: 130,
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: [
                ]
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;